body {
  background-color: #f2f2f2;
}

@media print {
  #zabbixTable {
    display: none;
  }
}

/* Login */
.login-container {
  background: #12a1f3;
  border: 1px solid #12a1f3;
  border-radius: 5px;
}

.login-container .login-header {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.login-container .login-header img {
  max-width: 50%;
}

.login-container .login-header h1 {
  color: #ffffff;
  font-size: 1.8em;
}

.login-container .login-body input {
  border-left: none;
}

.login-container .login-body button,
.login-container .login-body input {
  height: 50px;
}

.login-container .login-body button {
  width: 100%;
  border: none;
  min-height: 40px;
  font-weight: bold;
}

.login-container .login-body .input-group-text {
  border: none;
}

.login-container .login-body .input-group-text,
.login-container .login-body button {
  background-color: #ffc118;
  color: #ffffff;
}

.login-container .login-body .invalid-feedback {
  color: #721c24;
  background-color: #f8d7da;
  padding: 2.5%;
}

/* Navbar */
.navbar-brand img {
  max-width: 40px;
}

div.nav-item.dropdown {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

/* Buttons */
.btn-sm {
  font-size: 0.75em;
}

.btn-rounded {
  background-color: #efefef;
  border: 1px solid #efefef;
  border-radius: 30px;
  transition: color 0.2s linear;
}

/* Table */
.table {
  font-size: 0.75em;
}

.statement {
  font-size: 0.7em;
}

/* Cards */
.card.search,
.card.search .btn {
  font-size: 0.8em;
}

.card-footer select {
  width: 50px;
  padding-left: 7px;
  padding-right: 0;
}

.card-footer span {
  line-height: 36px;
  font-size: 0.75em;
  color: #757575;
}

/* Backgrounds */
.bg-primary {
  background-color: #cce5ff !important;
  color: #004085;
}

.bg-success {
  background-color: #d4edda !important;
  color: #155724;
}

.bg-danger {
  background-color: #f8d7da !important;
  color: #721c24;
}

.bg-warning {
  color: #856404;
  background-color: #fff3cd !important;
}

/* Other */
.task-status > div > span {
  font-size: 0.75em;
  font-weight: bold;
}

/* Preloader */
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  animation-delay: 1s;
  position: fixed;
  z-index: 1000;
  background: rgba(242, 242, 242, 0.8);
  width: 100%;
}

.preloader .item-1 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #eed968;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  100% {
    opacity: 0;
  }
}

.preloader .item-1:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eed968;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 200ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.preloader .item-2 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #eece68;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  100% {
    opacity: 0;
  }
}

.preloader .item-2:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eece68;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 400ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.preloader .item-3 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #eec368;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  100% {
    opacity: 0;
  }
}

.preloader .item-3:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eec368;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 600ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.preloader .item-4 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #eead68;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  100% {
    opacity: 0;
  }
}

.preloader .item-4:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eead68;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 800ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

.preloader .item-5 {
  width: 20px;
  height: 20px;
  background: #f583a1;
  border-radius: 50%;
  background-color: #ee8c68;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  100% {
    opacity: 0;
  }
}

.preloader .item-5:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ee8c68;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
  animation-delay: 1000ms;
  transition: 0.5s all ease;
  transform: scale(1);
}

/* Modal styles */
.backdropStyle {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  /* padding: 50px; */
}

.modalStyle {
  background-color: #fff;
  border-radius: 5px;
  max-width: 500px;
  min-height: 300px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}

.modalFooter {
  position: absolute;
  bottom: 20px;
}

/* new styles */
.login {
  height: 100vh;
  background-color: #264eca;
}

.login-input {
  background-color: transparent !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff !important;
  opacity: 0.5 !important; /* Firefox */
  font-weight: 25;
}

.reset-text {
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 250px;
  background-color: #2658d9;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: #ffffff;
  padding: 16px;
  text-decoration: none;
  opacity: 0.7;
}

.sidebar a.active {
  background-color: #04aa6d;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.menu-icon {
  opacity: 0.8;
}

div.content {
  margin-left: 250px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}


.text-red {
  color: red;
}

.button2two {
  margin-left: 5px;
}